import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { TaskNanoID } from '../../../tasksTypes';
import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import {
  DOWNLOAD_TASK_RESULTS_QUERY,
  DownloadTaskResultsQueryResponse
} from '../../../queries/downloadTaskResults.query';

import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadTaskResults } from '../../../hooks/useDownloadTaskResults';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDownloadManagerModal } from '../../../../common/components/footers/DefaultFooter/components/UserDownloadManagerModalButton/hooks/useDownloadManagerModal';

import { DownloadManagerModalItems } from '../../../../common/components/footers/DefaultFooter/components/UserDownloadManagerModalButton/components/DownloadManagerModalItems';

import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { DownloadCache } from '../../../../downloads/DownloadCache';
import { downloadsKeys, words } from '../../../../../locales/keys';

interface DownloadTaskResultsModalButtonProps {
  taskNanoId: TaskNanoID;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  i18nTextClassName?: ClassName;
  tooltipPlacement?: TooltipPlacement;
}

function DownloadTaskResultsModalButton({
  taskNanoId,
  className,
  icon,
  iconClassName,
  i18nText,
  i18nTextClassName,
  tooltipI18nText,
  tooltipPlacement
}: DownloadTaskResultsModalButtonProps) {
  const {
    downloadManagerBlockOpened,
    downloads,
    downloadsFetched,
    downloadsError,
    handleStopAll,
    handleClear,
    deleteDownloadsErrorMessage,
    deleteDownloadsLoading
  } = useDownloadManagerModal();

  const { downloadNanoId } = useDownloadNanoId();

  const {
    downloadTaskResults,
    downloadTaskResultsErrorMessage,
    downloadTaskResultsLoading
  } = useDownloadTaskResults<DownloadTaskResultsQueryResponse>({
    query: DOWNLOAD_TASK_RESULTS_QUERY,
    cacheKeys: [DownloadCache.indexCacheKey()]
  });

  useShowToastOnErrorChange({
    error: downloadTaskResultsErrorMessage || deleteDownloadsErrorMessage
  });

  const handleDownloadTaskResults = useCallback<() => void>(
    () =>
      downloadTaskResults({
        uuid: taskNanoId,
        deviceNanoId: downloadNanoId
      }),
    [taskNanoId, downloadNanoId, downloadTaskResults]
  );

  return (
    <SimpleModalButton
      className={
        className ||
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0'
      }
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      iconClassName={iconClassName || 'h-6 w-6'}
      icon={icon || IconsEnum.DOWNLOAD_OUTLINE}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      disabled={downloadTaskResultsLoading}
      onOpen={handleDownloadTaskResults}
      i18nTitle={downloadsKeys.plural}
      isModalOpen={downloadManagerBlockOpened}
      submitClass="font-medium rounded-md px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      submitButtonColor="gray"
      i18nSubmitText={words.stopAll}
      onSubmit={handleStopAll}
      onCancel={handleClear}
      i18nCancelText={words.clear}
      closeOnCancel={false}
      cancelButtonPosition="start"
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      withoutButtons={isEmpty(downloads)}
      isLoading={deleteDownloadsLoading}
    >
      <DownloadManagerModalItems
        downloads={downloads}
        downloadsFetched={downloadsFetched}
        downloadsError={downloadsError}
      />
    </SimpleModalButton>
  );
}

export default DownloadTaskResultsModalButton;
