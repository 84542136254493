import {
  TaskGqlError,
  TaskUUID,
  TaskMark,
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus,
  UpdateTaskCacheKeys
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface AcceptResultsInTaskOptions {
  query: UpdateTaskGqlQuery;
  cacheKeys?: UpdateTaskCacheKeys;
}

export interface AcceptResultsInTaskResponse<AcceptResultsInTaskRecordType> {
  acceptResultsInTask: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    record: AcceptResultsInTaskRecordType;
    errors: AcceptResultsInTaskError;
  };
}

export interface AcceptResultsInTaskInput {
  uuid: TaskUUID;
  mark: TaskMark;
  feedback?: string;
  withoutAccept?: boolean;
  withoutClose?: boolean;
}

export interface AcceptResultsInTaskError {
  fullMessages: TaskGqlError;
}

const action = 'acceptResultsInTask';

const scope = 'task';

const pluralScope = 'tasks';

function useAcceptResultsInTask<AcceptResultsInTaskRecordType>({
  query,
  cacheKeys
}: AcceptResultsInTaskOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    AcceptResultsInTaskInput,
    AcceptResultsInTaskResponse<AcceptResultsInTaskRecordType>,
    AcceptResultsInTaskError,
    AcceptResultsInTaskRecordType
  >({ action, pluralScope, scope, query, cacheKeys });

  return {
    acceptResultsInTaskData: updateQueryData,
    acceptResultsInTaskError: updateQueryError,
    acceptResultsInTaskLoading: updateQueryLoading,
    acceptResultsInTaskErrorMessage: updateQueryErrorMessage,
    acceptResultsInTask: updateQuery,
    acceptResultsInTaskReset: updateQueryReset
  };
}

export default useAcceptResultsInTask;
